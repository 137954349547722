import React, { ReactNode, useState } from 'react';
import {
  UseCollectionResult,
  useCollection,
} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import {
  ButtonDropdown,
  FlashbarProps,
  Pagination,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';

import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import Box from '@amzn/awsui-components-react/polaris/box';
import { BulkModal } from './BulkModal';
import {
  NotificationActionType,
  useNotifications,
} from 'src/hooks/notifications';
import { useParams } from 'react-router-dom';
import { ERROR_HEADER_NO_ITEMS_TO_EXPORT } from 'src/constants';
import { devicesToBeExportedFn, handleContent, handleHeader } from './helpers';

interface EmptyTableStateProps {
  action?: ReactNode;
  subtitle: string;
  title: string;
}

type TrackedEvent = `EVENT_${string}`;

function EmptyTableState({ title, subtitle, action }: EmptyTableStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export function DeviceTable({
  tableItems,
  tableColumns,
  sectionHeader,
}: {
  sectionHeader: string;
  tableColumns: any[];
  tableItems: any[];
}) {
  const [bulkTestVisible, setBulkTestVisible] = useState(false);
  const [bulkModalAction, setBulkModalAction] = useState('');

  const showBulkModal = (action: string) => {
    setBulkModalAction(action);
    setBulkTestVisible(true);
  };

  const { dispatch } = useNotifications();
  const { siteName } = useParams();

  const {
    items,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    allPageItems,
  } = useCollection(tableItems || [], {
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'All Tests Passed',
          key: 'tests_successful',
          groupValuesLabel: 'Test Result Values',
          operators: ['=', '!='],
        },
        {
          propertyLabel: 'Parent Device Name',
          key: 'parent_device_name',
          groupValuesLabel: 'Parent Device Name Values',
          operators: [':', '!:', '=', '!='],
        },
        {
          propertyLabel: 'Device Name',
          key: 'device_name',
          groupValuesLabel: 'Device Name Values',
          operators: [':', '!:', '=', '!='],
        },
        {
          propertyLabel: 'Device ID',
          key: 'device_id',
          groupValuesLabel: 'Device ID Values',
          operators: [':', '!:', '=', '!='],
        },
        {
          propertyLabel: 'Linked Device ID',
          key: 'linked_device_id',
          groupValuesLabel: 'Linked Device ID Values',
          operators: [':', '!:', '=', '!='],
        },
        // Currently the only way of filtering a list; waiting for https://issues.amazon.com/issues/AWSUI-18334
        {
          propertyLabel: 'Device Testers',
          key: 'testers',
          groupValuesLabel: 'Device Testers',
          operators: [':', '!:'],
        },
      ],
      empty: (
        <EmptyTableState title="No devices" subtitle="No devices to display." />
      ),
      noMatch: (
        <EmptyTableState title="No matches" subtitle="We can't find a match." />
      ),
      defaultQuery: {
        operation: 'and',
        tokens: [{ operator: ':', propertyKey: 'device_name', value: 'uc_' }],
      },
    },
    pagination: {
      pageSize: 5,
    },
    sorting: {},
    selection: {},
  });

  const selectedItems = collectionProps.selectedItems || [];

  // Export passed tests for a specific section
  const exportSectionDevices = ({
    filteredItems,
    filters,
    selectedDevices,
    tableDevices,
  }: {
    filteredItems: readonly any[];
    filters: UseCollectionResult<any>['propertyFilterProps'];
    selectedDevices: any;
    tableDevices: any[];
  }) => {
    const devicesToBeExported: readonly any[] = devicesToBeExportedFn(
      selectedDevices,
      filteredItems,
      filters,
      tableDevices
    );

    if (devicesToBeExported.length == 0) {
      const message: FlashbarProps.MessageDefinition = {
        type: 'error',
        header: ERROR_HEADER_NO_ITEMS_TO_EXPORT,
      };

      dispatch({
        type: NotificationActionType.ADD_NOTIFICATION,
        message: message,
      });
      return;
    }

    // Grab only event columns
    const trackedEvents = Object.keys(devicesToBeExported[0]).filter(k =>
      k.startsWith('EVENT_')
    ) as TrackedEvent[];

    let csvContent = handleHeader(trackedEvents);

    // handleContent
    csvContent = handleContent(devicesToBeExported, csvContent, trackedEvents);

    const csvBlob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `SiteTestingExport-${siteName}-${sectionHeader}.csv`
    );
    a.click();
  };

  return (
    <div>
      <Table
        {...collectionProps}
        columnDefinitions={tableColumns}
        items={items}
        trackBy="device_id"
        wrapLines={false}
        sortingDisabled={false}
        resizableColumns={true}
        filter={
          <PropertyFilter
            i18nStrings={{
              filteringAriaLabel: 'your choice',
              dismissAriaLabel: 'Dismiss',
              filteringPlaceholder: 'Search',
              groupValuesText: 'Values',
              groupPropertiesText: 'Properties',
              operatorsText: 'Operators',
              operationAndText: 'and',
              operationOrText: 'or',
              operatorLessText: 'Less than',
              operatorLessOrEqualText: 'Less than or equal',
              operatorGreaterText: 'Greater than',
              operatorGreaterOrEqualText: 'Greater than or equal',
              operatorContainsText: 'Contains',
              operatorDoesNotContainText: 'Does not contain',
              operatorEqualsText: 'Equals',
              operatorDoesNotEqualText: 'Does not equal',
              editTokenHeader: 'Edit filter',
              propertyText: 'Property',
              operatorText: 'Operator',
              valueText: 'Value',
              cancelActionText: 'Cancel',
              applyActionText: 'Apply',
              allPropertiesLabel: 'All properties',
              tokenLimitShowMore: 'Show more',
              tokenLimitShowFewer: 'Show fewer',
              clearFiltersText: 'Clear filters',
              removeTokenButtonAriaLabel: () => 'Remove token',
              enteredTextLabel: text => `Use: "${text}"`,
            }}
            countText={`${filteredItemsCount} matches`}
            {...propertyFilterProps}
          />
        }
        header={
          <Header
            variant="h3"
            counter={
              tableItems &&
              (selectedItems.length
                ? `${selectedItems.length} / ${tableItems.length} devices selected`
                : `${tableItems.length} devices`)
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  iconName="download"
                  disabled={false}
                  onClick={() =>
                    exportSectionDevices({
                      filteredItems: allPageItems,
                      filters: propertyFilterProps,
                      selectedDevices: selectedItems,
                      tableDevices: tableItems,
                    })
                  }>
                  Export {sectionHeader.replace(/s/g, '')} devices
                </Button>
                <ButtonDropdown
                  variant="primary"
                  items={[
                    {
                      id: 'test',
                      text: selectedItems.length
                        ? `Test selected (${selectedItems.length})`
                        : 'Test all',
                      disabled: false,
                    },
                    {
                      id: 'cancel',
                      text: selectedItems.length
                        ? `Cancel selected (${selectedItems.length})`
                        : 'Cancel all',
                      disabled: false,
                    },
                  ]}
                  onItemClick={ev => showBulkModal(ev.detail.id)}>
                  {selectedItems.length
                    ? 'Test or cancel selected'
                    : 'Test or cancel all'}
                </ButtonDropdown>
              </SpaceBetween>
            }
          />
        }
        pagination={<Pagination {...paginationProps} />}
        variant="embedded"
        selectionType="multi"
        empty={
          <EmptyTableState
            title="No devices"
            subtitle="There are no devices to display."
          />
        }
      />

      {bulkTestVisible ? (
        <BulkModal
          deviceItems={
            selectedItems.length
              ? selectedItems
              : filteredItemsCount && filteredItemsCount == tableItems.length
              ? tableItems
              : items
          }
          eventColumns={tableColumns}
          isVisible={bulkTestVisible}
          setVisible={setBulkTestVisible}
          action={bulkModalAction}
        />
      ) : null}
    </div>
  );
}
