import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';

function PageHeader({ exportSiteDevices }: { exportSiteDevices: any }) {
  const { siteName } = useParams();

  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            iconName="download"
            disabled={false}
            onClick={() => exportSiteDevices()}>
            Export All devices
          </Button>
        </SpaceBetween>
      }>
      {siteName && siteName.toUpperCase()}
    </Header>
  );
}

export default PageHeader;
