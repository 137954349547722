import { createState } from '@hookstate/core';

export interface AppStateInterface {
  loadedSites: any[];
  stage: string;
  user: {
    userGroups: string[];
    username: string | undefined;
  };
}

export const appBaseState = createState<AppStateInterface>({
  user: {
    username: undefined,
    userGroups: [],
  },
  stage: 'beta',
  loadedSites: [],
});
