import React, { PropsWithChildren, useState } from 'react';
import { EventsTableItem } from 'src/interfaces';
import { DeviceListContext } from './context';
import { DeviceListProps } from './types';

export function DeviceListProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [inputoutputDevices, setInputoutputDevices] = useState<
    EventsTableItem[]
  >([]);
  const [keypads, setKeypads] = useState<EventsTableItem[]>([]);
  const [readers, setReaders] = useState<EventsTableItem[]>([]);

  const value: DeviceListProps = {
    inputoutputDevices,
    keypads,
    readers,
    setInputoutputDevices,
    setKeypads,
    setReaders,
  };
  return (
    <DeviceListContext.Provider value={value}>
      {children}
    </DeviceListContext.Provider>
  );
}
