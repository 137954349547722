export type LoadingAction = {
  type: string;
};

export type LoadingState = {
  loading: boolean;
};

export const SERVER_REQUEST_START = 'SERVER_REQUEST_START';
export const SERVER_REQUEST_END = 'SERVER_REQUEST_END';

export const LoadingReducer = (
  state: LoadingState,
  action: LoadingAction
): LoadingState => {
  switch (action.type) {
    case SERVER_REQUEST_START:
      return { ...state, loading: true };
    case SERVER_REQUEST_END:
      return { ...state, loading: false };
  }
  return state;
};
