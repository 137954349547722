export const APP_BASE_TITLE = 'Site Testing v2';

export const NAV_LINKS = {
  SITE_TESTING_PUBLIC_WIKI:
    'https://w.amazon.com/bin/view/AmazonCorporateSecurity/TechnicalSecurity/Infrastructure/Services/SiteTestingTool',
  GREENLIGHT_FEEDBACK_SIM:
    'https://t.corp.amazon.com/create/templates/65de1412-b246-47a4-8e19-d25a66c6ea44',
  SITE_TESTING_BUG_SIM:
    'https://t.corp.amazon.com/create/templates/bb15d8a2-bf97-4cac-8022-a3bd8cd7a807',
};

export const ONGUARD_COMMAND_KEYPAD_IDS = [144, 151, 152, 153];

export const TRACKED_EVENT = {
  DOOR_HELD_OPEN: 'Door Held Open',
  DOOR_HELD_OPEN_CANCELED: 'Door Held Open Canceled',
};

export const ERROR_HEADER_NO_ITEMS_TO_EXPORT = 'No items to export';
