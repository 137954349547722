import * as React from 'react';
import { DeviceListContext } from './context';
import { DeviceListProps } from './types';

export function useDeviceList(): DeviceListProps {
  const context = React.useContext(DeviceListContext);
  if (typeof context === 'undefined') {
    throw new Error(
      'useDeviceList hook must be used in the scope of DeviceListProvider'
    );
  }
  return context;
}
