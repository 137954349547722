import {
  EventsTableItem,
  PACSDevice,
  ReaderConfigData,
  SiteTestingTestData,
  accessEventTestData,
} from 'src/interfaces';

export const SECTION_INPUT_OUTPUT = 'Inputs and Outputs';
export const SECTION_READERS = 'Readers';
export const SECTION_KEYPADS = 'Command Keypad';
const EVENT_INTRUSION = 'Intrusion Command Accepted';

const CSV_SEP = ',';
const CSV_NA = 'N/A';
const CSV_FAIL = 'FAIL';
const CSV_SUCCESS = 'Test Succeeded';
const CSV_NOT_TESTED = 'Not tested';

export function isNA(
  deviceType: string | undefined | null,
  trackedEvent: string,
  readerConfig: ReaderConfigData | undefined
) {
  // Hide unrelated tests for input devices
  if (
    deviceType &&
    deviceType.toLowerCase().includes('input') &&
    !trackedEvent.includes('Alarm')
  )
    return true;

  // Hide unrelated tests for output devices
  if (
    deviceType &&
    deviceType.toLowerCase().includes('output') &&
    !trackedEvent.includes('Relay')
  )
    return true;

  // Hide tests based on reader configuration
  if (trackedEvent.includes('REX') && readerConfig === undefined) return true;

  if (readerConfig) {
    if (
      readerConfig.DONOTWAIT === 1 &&
      trackedEvent === 'Access Granted No Entry Made'
    )
      return true;

    if (
      (readerConfig.DOORCONTACT_SUPERVISION === 1 ||
        readerConfig.DOORCONTACT_SUPERVISION === null) &&
      [
        'Door Forced Open',
        'Door Held Open',
        'Door Forced Open Canceled',
        'Door Held Open Canceled',
      ].includes(trackedEvent)
    )
      return true;

    if (
      readerConfig.PAIRSLAVE === 1 &&
      [
        'Door Held Open',
        'Door Forced Open',
        'Door Forced Open Canceled',
        'Door Held Open Canceled',
      ].includes(trackedEvent)
    )
      return true;

    if (
      (readerConfig.PAIRMASTER === 1 ||
        readerConfig.PAIRSLAVE === 1 ||
        readerConfig.REX_SUPERVISION === 1 ||
        readerConfig.REX_SUPERVISION === null) &&
      trackedEvent.includes('REX')
    )
      return true;
  }

  return false;
}

export function getSectionEvents(
  section: string,
  trackedEvents: string[]
): string[] {
  let result: string[] = trackedEvents;
  if (section === SECTION_READERS) {
    result = trackedEvents.filter(event => event != EVENT_INTRUSION);
  } else if (section == SECTION_KEYPADS) {
    result = trackedEvents.filter(event => event == EVENT_INTRUSION);
  }
  return result;
}

export function getEventIdFromName(eventName: string) {
  return `EVENT_${eventName.replace(/ /g, '_')}`;
}

export function getReaderConfig(siteReaderConfigs: any[], device: PACSDevice) {
  const readerConfig = siteReaderConfigs.find(
    config =>
      config.PANELID === device.Parent_DeviceID &&
      config.READERID === device.Child_DeviceID
  );

  return readerConfig;
}

export function handleContent(
  devicesToBeExported: readonly any[],
  csvContent: string,
  trackedEvents: `EVENT_${string}`[]
) {
  devicesToBeExported.forEach(device => {
    csvContent += `
        ${device.device_name || ''},${device.device_id || ''},${
      device.parent_device_id || ''
    },${device.child_device_id || ''},${device.subchild_device_id || ''}`;
    trackedEvents.forEach(event => {
      if (isEventREX(event)) {
        csvContent += createDeviceEventValueForREX(device, event);
      } else {
        csvContent += createDeviceEventValue(device, event);
      }
    });
  });
  return csvContent;
}

function isEventREX(event: string) {
  return event.includes('REX');
}

function createDeviceEventValue(device: EventsTableItem, event: string) {
  const eventData: SiteTestingTestData | null = accessEventTestData(
    device,
    event
  );
  if (eventData === null) return;

  const base = CSV_SEP;

  if (eventData.isNA) return base.concat(CSV_NA);

  if (
    eventData.testSubmittedBy &&
    (!eventData.lastSuccessID ||
      eventData.lastSuccessID !== eventData.lastTestID)
  )
    return base.concat(CSV_FAIL);

  if (
    eventData.lastSuccessID &&
    eventData.testSubmittedBy &&
    eventData.lastSuccessID === eventData.lastTestID
  )
    return base.concat(CSV_SUCCESS);

  return base.concat(CSV_NOT_TESTED);
}

function createDeviceEventValueForREX(device: EventsTableItem, event: string) {
  const eventData: SiteTestingTestData | null = accessEventTestData(
    device,
    event
  );
  if (eventData === null) return;

  const base = CSV_SEP;

  if (eventData.isNA) return base.concat(CSV_NA);

  if (eventData.lastEventReceived && eventData.lastTestEndTS < Date.now())
    return base.concat(CSV_SUCCESS);

  if (eventData.rexTestFailed) return base.concat(CSV_FAIL);

  return base.concat(CSV_NOT_TESTED);
}

export function handleContentGlobal(
  devicesToBeExported: EventsTableItem[],
  csvContent: string
) {
  devicesToBeExported.forEach(device => {
    csvContent += `
        ${device.device_name || ''},${device.device_id || ''},${
      device.device_type || ''
    },${device.device_name.includes('uc_') || false},${
      device.parent_device_id || ''
    },${device.child_device_id || ''},${device.subchild_device_id || ''}`;

    if (device.tests_successful) {
      csvContent += ',PASSED';
    } else {
      csvContent += ',FAILED';
    }
  });
  return csvContent;
}

export function devicesToBeExportedFn(
  selectedDevices: any,
  filteredItems: readonly any[],
  filters: any,
  tableDevices: any[]
) {
  let devicesToBeExported: readonly any[];

  if (selectedDevices.length) {
    devicesToBeExported = selectedDevices;
  } else if (filteredItems.length || filters.query.tokens) {
    devicesToBeExported = filteredItems;
  } else {
    devicesToBeExported = tableDevices;
  }
  return devicesToBeExported;
}

export function handleHeader(trackedEvents: `EVENT_${string}`[]) {
  let csvContent = 'deviceName,deviceID,parentID,childID,subchildID';

  trackedEvents.forEach(event => {
    csvContent += ',' + event;
  });
  return csvContent;
}
