import React, { useEffect, useState } from 'react';
import { GetParametersCommand, SSMClient } from '@aws-sdk/client-ssm';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { DeviceTable } from './DeviceTable';
import { Auth } from 'aws-amplify';

import { FlashbarProps, TableProps } from '@amzn/awsui-components-react';
import {
  NotificationActionType,
  useNotifications,
} from 'src/hooks/notifications';
import { EventsTableItem, PACSDevice } from 'src/interfaces';
import { DeviceSectionProps } from 'src/types';
import { ONGUARD_COMMAND_KEYPAD_IDS } from 'src/constants';
import DeviceCellInfo from './DeviceCellInfo';
import EventCellInfo from './EventCellInfo';
import {
  SECTION_INPUT_OUTPUT,
  SECTION_KEYPADS,
  SECTION_READERS,
  getReaderConfig,
  getSectionEvents,
  isNA,
} from './helpers';
import { useDeviceList } from 'src/features/deviceList';

export default function DeviceTests({
  initSiteDevices,
  siteReaderConfigs,
  linkedSiteDevices,
  siteEvents,
}: {
  initSiteDevices: [];
  linkedSiteDevices: [] | null;
  siteEvents: any[];
  siteReaderConfigs: [] | null;
}) {
  return (
    <SpaceBetween size="m">
      <DeviceSection
        sectionDeviceType={['card_reader']}
        sectionDeviceHeader="Readers"
        siteDevices={initSiteDevices}
        siteEvents={siteEvents}
        linkedSiteDevices={linkedSiteDevices}
        siteReaderConfigs={siteReaderConfigs}
      />
      <DeviceSection
        sectionDeviceType={['input', 'output']}
        sectionDeviceHeader="Inputs and Outputs"
        siteDevices={initSiteDevices}
        siteEvents={siteEvents}
        linkedSiteDevices={linkedSiteDevices}
      />
      <DeviceSection
        sectionDeviceType={['card_reader']}
        sectionDeviceHeader="Command Keypad"
        siteDevices={initSiteDevices}
        siteEvents={siteEvents}
        linkedSiteDevices={linkedSiteDevices}
      />
    </SpaceBetween>
  );
}

function DeviceSection({
  sectionDeviceType,
  sectionDeviceHeader,
  siteDevices,
  linkedSiteDevices,
  siteReaderConfigs,
  siteEvents,
}: DeviceSectionProps) {
  const [sectionLoading, setSectionIsLoading] = useState(true);
  const [trackedEvents, setTrackedEvents] = useState<string[]>([]);
  const [tableItems, setTableItems] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<any>([]);

  const trackedEventsSet = React.useRef(false);
  const tableColumnsSet = React.useRef(false);
  const { dispatch } = useNotifications();
  const { setReaders, setInputoutputDevices, setKeypads } = useDeviceList();

  const buildTableColumns = () => {
    if (tableColumns.length > 0) return;

    // Build our table columns from tracked events for the section
    let columns: TableProps.ColumnDefinition<any>[] = trackedEvents.map(
      trackedEvent => ({
        id: trackedEvent,
        header: trackedEvent,
        cell: (item: any) => (
          <EventCellInfo
            trackedEvent={trackedEvent}
            deviceType={item.device_type}
            deviceName={item.device_name}
            deviceID={item.device_id}
            linkedDevice={
              item.linked_device_id ? item.linked_device_id : undefined
            }
            readerConfig={item.reader_config ? item.reader_config : undefined}
            eventData={item[`EVENT_${trackedEvent.replace(/ /g, '_')}`]}
          />
        ),
        sortingField: undefined,
      })
    );

    // Keypad events need to be removed from our readers
    if (sectionDeviceHeader == 'Readers') {
      columns = columns.filter(col => col.id != 'Intrusion Command Accepted');
    } else if (sectionDeviceHeader == 'Command Keypad') {
      columns = columns.filter(col => col.id == 'Intrusion Command Accepted');
    }

    // Add our device name to table column
    columns.unshift({
      id: 'device_name',
      header: 'Device Name',
      cell: (item: EventsTableItem) => <DeviceCellInfo item={item} />,
      sortingField: 'device_name',
    });

    // Add parent device for our I/O section
    if (sectionDeviceHeader.toLowerCase().includes('inputs')) {
      columns.unshift({
        id: 'parent_device_name',
        header: 'Parent Device',
        cell: (item: any) => item.parent_device_name,
        sortingField: 'parent_device_name',
      });
    }

    setTableColumns(columns);
  };

  const filteredSectionTypes = () => {
    // Filter out devices based on device type for the section
    let devices: PACSDevice[] = [];

    if (sectionDeviceHeader == 'Inputs and Outputs') {
      // for IO devices, we check if it contains 'input' or 'output' in the device type
      devices = siteDevices.filter((device: PACSDevice) =>
        sectionDeviceType.some(type => device.Device_Type.includes(type))
      );
    } else {
      // for everything else, we just check for a match with any values in sectionDeviceType
      devices = siteDevices.filter((device: PACSDevice) =>
        sectionDeviceType.includes(device.Device_Type)
      );
    }

    // Filter out keypad type IDs from devices since they're set up as card_reader
    if (sectionDeviceHeader == 'Readers') {
      devices = devices.filter(device =>
        device.Device_Type_Id
          ? !ONGUARD_COMMAND_KEYPAD_IDS.includes(device.Device_Type_Id)
          : true
      );
    } else if (sectionDeviceHeader == 'Command Keypad') {
      devices = devices.filter(device =>
        device.Device_Type_Id
          ? ONGUARD_COMMAND_KEYPAD_IDS.includes(device.Device_Type_Id)
          : false
      );
    }

    return devices;
  };

  const buildTableItems = () => {
    // By default, this is the field we use from Normalizer to grab the device name.
    let deviceNameField: 'Child_DeviceName' | 'SubChild_DeviceName' =
      'Child_DeviceName';

    // Swap the field used for device name based on types
    if (sectionDeviceHeader.toLowerCase().includes('inputs')) {
      deviceNameField = 'SubChild_DeviceName';
    }

    const dedupe = new Set();
    const deviceTypes = filteredSectionTypes();

    const devices = deviceTypes.filter(device => {
      const dupe = dedupe.has(device[deviceNameField as keyof PACSDevice]);
      dedupe.add(device[deviceNameField as keyof PACSDevice]);
      return !dupe;
    });

    const itemList = devices.map((device: PACSDevice) => {
      const item = {} as EventsTableItem;

      const deviceID = `${device.Parent_DeviceID}_${device.Child_DeviceID}_${device.Subchild_DeviceID}`;

      item.device_name = device[deviceNameField];
      item.device_id = deviceID;
      item.parent_device_name = device.Parent_DeviceName;
      item.child_device_name = device.Child_DeviceName;
      item.subchild_device_name = device.SubChild_DeviceName;
      item.device_source = device.devicesource;
      item.device_type = device.Device_Type;
      item.parent_device_id = device.Parent_DeviceID;
      item.child_device_id = device.Child_DeviceID;
      item.subchild_device_id = device.Subchild_DeviceID;
      item.parent_device_name = device.Parent_DeviceName;
      item.tests_successful = true;
      item.testers = [];

      if (siteReaderConfigs) {
        item.reader_config = getReaderConfig(siteReaderConfigs, device);
      }

      const sectionEvents: string[] = getSectionEvents(
        sectionDeviceHeader,
        trackedEvents
      );

      sectionEvents.forEach(event => {
        const eventData =
          siteEvents.find(
            ev =>
              `${device.Parent_DeviceID}_${device.Child_DeviceID}_${device.Subchild_DeviceID}` ===
                ev.deviceID && event === ev.eventName
          ) || {};

        const na =
          !!item.device_type &&
          !!event &&
          isNA(item.device_type, event, item.reader_config);

        if (
          eventData.testSubmittedBy &&
          !item.testers.includes(eventData.testSubmittedBy)
        )
          item.testers.push(eventData.testSubmittedBy);

        if (
          !na &&
          (!eventData.eventReceivedSuccess ||
            eventData.eventReceivedSuccess == null)
        ) {
          if (sectionDeviceHeader == 'Readers' && !event.includes('Intrusion'))
            item.tests_successful = false;

          if (
            sectionDeviceHeader == 'Command Keypad' &&
            event.includes('Intrusion')
          )
            item.tests_successful = false;

          if (item.device_type.includes('input') && event.includes('Alarm'))
            item.tests_successful = false;

          if (item.device_type.includes('output') && event.includes('Relay'))
            item.tests_successful = false;
        }

        item[`EVENT_${event.replace(/ /g, '_')}`] = {
          lastEventTS: eventData.lastSuccessfulEventTimestampUTC || null,
          lastEventReceived: eventData.eventReceivedSuccess || null,
          rexTestFailed: eventData.rexTestFailed || null,
          lastSuccessID: eventData.lastSuccessID || null,
          lastTestID: eventData.lastTestID || null,
          lastTestTS: eventData.testStartTimestampUTC || null,
          lastTestEndTS: eventData.testEndTimestampUTC || null,
          isCancelled: eventData.isCancelled || false,
          testSubmittedBy: eventData.testSubmittedBy || null,
          testCancelledBy: eventData.testCancelledBy || null,
          previousTestID: eventData.previousTestID || null,
          isNA: na,
        };
      });

      // for input devices, add linked device ids
      if (sectionDeviceHeader == 'Inputs and Outputs' && linkedSiteDevices) {
        // find our input device to grab our output
        const linkedDevice = linkedSiteDevices.find(
          linkedDevice =>
            (linkedDevice.input_device_parent_id == device.Parent_DeviceID &&
              linkedDevice.input_device_child_id == device.Child_DeviceID &&
              linkedDevice.input_device_sub_child_id ==
                device.Subchild_DeviceID) ||
            (linkedDevice.output_device_parent_id == device.Parent_DeviceID &&
              linkedDevice.output_device_child_id == device.Child_DeviceID &&
              linkedDevice.output_device_sub_child_id ==
                device.Subchild_DeviceID)
        );

        const outputID = linkedDevice
          ? linkedDevice.input_device_sub_child_id == device.Subchild_DeviceID
            ? `${linkedDevice.output_device_parent_id}_${linkedDevice.output_device_child_id}_${linkedDevice.output_device_sub_child_id}`
            : `${linkedDevice.input_device_parent_id}_${linkedDevice.input_device_child_id}_${linkedDevice.input_device_sub_child_id}`
          : undefined;

        item.linked_device_id = outputID;
      }

      return item;
    });

    setTableItems(itemList);
    if (sectionDeviceHeader === SECTION_INPUT_OUTPUT) {
      setInputoutputDevices(itemList);
    } else if (sectionDeviceHeader === SECTION_READERS) {
      setReaders(itemList);
    } else if (sectionDeviceHeader === SECTION_KEYPADS) {
      setKeypads(itemList);
    }
    setSectionIsLoading(false);
  };

  const getTrackedEvents = () => {
    Auth.currentCredentials().then(creds => {
      const ssm = new SSMClient({
        region: 'us-east-1',
        credentials: Auth.essentialCredentials(creds),
      });

      let params: string[] = [];

      if (sectionDeviceHeader == 'Inputs and Outputs') {
        params = ['/sitetesting/beta/config/events/input_output'];
      } else {
        params = sectionDeviceType.map(
          type => `/sitetesting/beta/config/events/${type}`
        );
      }

      const ssmCommand = new GetParametersCommand({
        Names: params,
      });

      ssm
        .send(ssmCommand)
        .then(resp => {
          const ssmEvents: string[] = [];

          if (!resp.Parameters) {
            const message: FlashbarProps.MessageDefinition = {
              type: 'error',
              header: 'Failed to grab info from SSM',
              content:
                'There was an error fetching information from SSM. Refresh the page to try again.',
            };

            dispatch({
              type: NotificationActionType.ADD_NOTIFICATION,
              message: message,
            });

            throw new Error(
              `Parameter response from SSM was undefined for device types\n${JSON.stringify(
                resp
              )}`
            );
          }

          resp.Parameters.forEach(param => {
            if (param.Value) {
              const paramList = param.Value.split(',');

              paramList.forEach(
                event =>
                  void (
                    ssmEvents.indexOf(event) === -1 && ssmEvents.push(event)
                  )
              );
            }
          });

          setTrackedEvents(ssmEvents);
        })
        .catch(err => {
          const message: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: 'Unexpected response from SSM',
            content:
              'There was an unexpected response when fetching parameters from SSM. Refresh the page to try again.',
          };

          dispatch({
            type: NotificationActionType.ADD_NOTIFICATION,
            message: message,
          });

          throw new Error(`Unexpected SSM response.. ${err}`);
        });
    });
  };

  useEffect(() => {
    if (!trackedEventsSet.current) {
      getTrackedEvents();
      trackedEventsSet.current = true;
      return;
    }

    if (!tableColumnsSet.current) {
      buildTableColumns();
      tableColumnsSet.current = true;
    }

    buildTableItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteEvents, trackedEvents]);

  return (
    <ExpandableSection
      variant="container"
      defaultExpanded={true}
      header={<Header variant="h2">{sectionDeviceHeader}</Header>}>
      {!sectionLoading ? (
        <DeviceTable
          tableColumns={tableColumns}
          tableItems={tableItems}
          sectionHeader={sectionDeviceHeader}
        />
      ) : (
        <Spinner size="large" />
      )}
    </ExpandableSection>
  );
}
