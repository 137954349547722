import React, { useEffect, useState } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Select, {
  SelectProps,
} from '@amzn/awsui-components-react/polaris/select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Link from '@amzn/awsui-components-react/polaris/link';
import { API } from 'aws-amplify';
import { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { callPACS } from 'src/graphql/queries';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import { APP_BASE_TITLE, NAV_LINKS } from 'src/constants';
interface SiteSelectionProps {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function SiteNavigation({ isVisible, setVisible }: SiteSelectionProps) {
  const [canClose, setCanClose] = useState(true);
  const [pacsSites, setPacsSites] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSite, setSelectedSite] = useState<SelectProps.Option | null>(
    null
  );
  const appState = useHookstate(appBaseState);

  const navigate = useNavigate();
  const location = useLocation();
  const { siteName } = useParams();

  // set our page title
  useEffect(() => {
    document.title = `${APP_BASE_TITLE} ${
      appState.stage.value === 'prod'
        ? ''
        : `:: ${appState.stage.value.toUpperCase()}`
    } ${siteName ? `- ${siteName.toUpperCase()}` : ''}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    let isMounted = true;

    const fetchSites = async () => {
      return await (API.graphql(
        graphqlOperation(callPACS, {
          pacsMethod: 'getSites',
        })
      ) as Promise<GraphQLResult<any>>);
    };

    fetchSites().then(res => {
      const pacsSites = JSON.parse(res.data?.callPACS.response);
      appState.loadedSites.set(pacsSites);

      const siteList: Array<{ label: string; value: string }> = [];

      pacsSites.forEach((pacsSite: any) => {
        siteList.push({
          label: pacsSite.SiteCode,
          value: pacsSite.SiteCode,
        });
      });

      if (isMounted) {
        setPacsSites(siteList);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = document.location.pathname;

    if (path === '/') {
      setVisible(true);
      setCanClose(false);
    } else if (path.startsWith('/site/')) {
      const site = path.split('/')[2];
      setSelectedSite({ value: site, label: site.toUpperCase() });
      setCanClose(true);
      setVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function handleOkay() {
    setVisible(false);
    return navigate(`/site/${selectedSite?.value?.toUpperCase()}`);
  }

  return (
    <Modal
      visible={isVisible}
      onDismiss={() => {
        if (canClose) setVisible(false);
      }}
      header="Select a site"
      footer={
        <>
          <Box float="left">
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href={NAV_LINKS.SITE_TESTING_PUBLIC_WIKI}>
              Help
            </Link>
          </Box>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                disabled={!canClose}
                onClick={() => setVisible(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => handleOkay()}
                disabled={selectedSite === null ? true : false}>
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }>
      <Select
        selectedOption={selectedSite}
        onChange={({ detail }) => {
          setSelectedSite(detail.selectedOption);
        }}
        filteringType="auto"
        filteringPlaceholder="Filter for a site"
        statusType={isLoading ? 'loading' : 'finished'}
        options={pacsSites}
        placeholder="Choose a site"
        selectedAriaLabel="Selected"
        empty="No options"
        loadingText="Fetching available sites"
      />
    </Modal>
  );
}

export default SiteNavigation;
