import { Link } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PACSDevice } from 'src/interfaces';

export default function SiteSum({
  siteDevices,
  siteEvents,
}: {
  siteDevices: PACSDevice[];
  siteEvents: any[];
}) {
  const location = useLocation();
  const { siteName } = useParams();

  const [lastTestUser, setLastTester] = useState('-');
  const [lastTestTime, setLastTestTime] = useState('-');
  const [passedEventsCount, setPassedEventCount] = useState('-');
  const [passedEventsPercent, setPassedEventsPercent] = useState('-');
  const [failedEventsCount, setFailedEventsCount] = useState('-');
  const [siteDeviceCount, setSiteDeviceCount] = useState('-');

  const calculateTestNumbers = () => {
    const events = [...siteEvents];

    const passedEvents = events.filter(
      ev => ev.lastSuccessID === ev.lastTestID && ev.lastSuccessID != null
    );
    const failedEvents = events.filter(
      ev =>
        (ev.lastSuccessID == null && ev.lastTestID != null) ||
        (ev.lastSuccessID != ev.lastTestID && ev.lastSuccessID != null)
    );
    const passedPercent = (
      (passedEvents.length / siteEvents.length) *
      100
    ).toFixed(1);

    setPassedEventCount(`${passedEvents.length}`);
    setFailedEventsCount(`${failedEvents.length}`);
    setPassedEventsPercent(`${passedPercent}%`);
  };

  const findLastTests = () => {
    const events = [...siteEvents];
    events.sort((a, b) => a.testStartTimestampUTC - b.testStartTimestampUTC);

    const lastEvent = events.pop();
    const lastTestTime = new Date(lastEvent.testStartTimestampUTC);
    // const relativeTime = new RelativeTime({ locale: 'en-GB' })

    // setLastTestTime(relativeTime.from(lastTestTime));
    setLastTester(lastEvent.testSubmittedBy);
    setLastTestTime(lastTestTime.toLocaleString());
  };

  const calculateNumDevices = () => {
    const totalCount = siteDevices.length;
    const ucDevices = siteDevices.filter(device =>
      device.Child_DeviceName
        ? device.Child_DeviceName.includes('uc_')
        : false || device.SubChild_DeviceName
        ? device.SubChild_DeviceName.includes('uc_')
        : false
    );

    setSiteDeviceCount(
      `${totalCount} (${totalCount - ucDevices.length} prod | ${
        ucDevices.length
      } uc)`
    );
  };

  useEffect(() => {
    calculateNumDevices();

    if (siteEvents.length < 1) return;

    calculateTestNumbers();
    findLastTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, siteEvents, siteDevices]);

  return (
    <Container header={<Header variant="h2">Overview</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Site</Box>
            <div>{`${siteName?.toUpperCase()}`}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Total devices</Box>
            <div>{siteDeviceCount}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Total tests</Box>
            <div>{siteEvents.length}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Tests passed</Box>
            <div>{passedEventsCount}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Tests failed</Box>
            <div>{failedEventsCount}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Percentage passed</Box>
            <div>{passedEventsPercent}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Last test</Box>
            <div>{lastTestTime}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Last tested by</Box>
            <div>
              {lastTestUser == '-' ? (
                '-'
              ) : (
                <Link
                  external
                  href={`https://phonetool.amazon.com/users/${lastTestUser}`}>
                  {lastTestUser}@
                </Link>
              )}
            </div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
