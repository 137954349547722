import { SiteTestingTestConditions } from 'src/types';

export interface SiteTestingTestData {
  isCancelled: boolean;
  isNA: boolean | null;
  lastEventReceived: boolean | null;
  lastEventTS: number | null;
  lastSuccessID: string | null;
  lastTestEndTS: number;
  lastTestID: string | null;
  lastTestTS: number | null;
  previousTestID: string | null;
  rexTestFailed: boolean;
  testCancelledBy: string | null;
  testSubmittedBy: string | null;
}

export interface ReaderConfigData {
  DONOTWAIT: number | null;
  DOORCONTACT_SUPERVISION: number | null;
  OPENTIME: number | null;
  PAIRMASTER: number | null;
  PAIRSLAVE: number | null;
  PANELID: number | null;
  READERDESC: string;
  READERID: number | null;
  REX_SUPERVISION: number | null;
}

export interface PACSDevice extends SiteTestingTestData {
  Child_DeviceID: number;
  Child_DeviceName: string;
  Device_Type: string;
  Device_Type_Id: number | null;
  Parent_DeviceID: number;
  Parent_DeviceName: string;
  SegmentID: number;
  SubChild_DeviceName: string;
  Subchild_DeviceID: number;
  device_href: string;
  devicesource: string;
}

export interface SiteTestingActionInput {
  deviceID: string;
  eventName: string;
  previousTestID?: string;
  testConditions?: SiteTestingTestConditions;
  testDuration?: number;
}

export interface EventsTableItem {
  [key: DynamicEventKey]: SiteTestingTestData;
  child_device_id: number;
  child_device_name: string;
  device_id: string;
  device_name: string;
  device_source: string;
  device_type: string;
  linked_device_id?: string;
  parent_device_id: number;
  parent_device_name?: string;
  reader_config?: ReaderConfigData;
  subchild_device_id: number;
  subchild_device_name: string;
  testers: string[];
  tests_successful: boolean;
}

export function accessEventTestData(
  obj: EventsTableItem,
  key: string
): SiteTestingTestData | null {
  if (isDynamicEventKey(key)) {
    if (obj[key]) {
      return obj[key];
    }
  }
  return null;
}

type DynamicEventKey = `EVENT_${string}`;

function isDynamicEventKey(key: string): key is DynamicEventKey {
  return key.startsWith('EVENT_');
}
