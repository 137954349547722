import React, { useEffect, useState } from 'react';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import { State } from '@hookstate/core';
import { AppStateInterface } from 'src/stores/app';
import { useLocation } from 'react-router-dom';
import { NAV_LINKS } from 'src/constants';
import logo from 'src/images/acs-logo-white.png';

interface TopNavProps {
  appState: State<AppStateInterface>;
  setSiteSelectVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function TopNav({ setSiteSelectVisible, appState }: TopNavProps) {
  const location = useLocation();

  const i18nStrings = {
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'All',
    overflowMenuBackIconAriaLabel: 'Back',
    overflowMenuDismissIconAriaLabel: 'Close menu',
  };

  const appName = `Site Testing v2 ${
    appState.stage.value === 'prod'
      ? ''
      : `:: ${appState.stage.value.toUpperCase()}`
  }`;
  const [site, setSite] = useState('');

  useEffect(() => {
    if (location.pathname.startsWith('/site/')) {
      setSite(location.pathname.split('/')[2].toUpperCase());
    } else {
      setSite('No site selected');
    }
  }, [location]);

  return (
    <div>
      <TopNavigation
        identity={{
          href: '/',
          title: appName,
          logo: {
            src: logo,
          },
        }}
        i18nStrings={i18nStrings}
        utilities={[
          // Site selection
          {
            type: 'button',
            text: site,
            ariaLabel: site,
            iconName: 'angle-right',
            onClick: () => setSiteSelectVisible(true),
          },
          // User
          {
            type: 'menu-dropdown',
            text: `${appState.user.username.value}@`,
            description: `Hi, ${appState.user.username.value}`,
            iconName: 'user-profile',
            items: [
              {
                id: 'support-group',
                text: 'Support',
                items: [
                  {
                    id: 'documentation',
                    text: 'End User Guide',
                    href: NAV_LINKS.SITE_TESTING_PUBLIC_WIKI,
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                  {
                    id: 'feedback',
                    text: 'Feedback or Feature Request',
                    href: NAV_LINKS.GREENLIGHT_FEEDBACK_SIM,
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                  {
                    id: 'report_bug',
                    text: 'Report a Bug',
                    href: NAV_LINKS.SITE_TESTING_BUG_SIM,
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                ],
              },
            ],
          },
        ]}
      />
    </div>
  );
}

export default TopNav;
