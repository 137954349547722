/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Flashbar, {
  FlashbarProps,
} from '@amzn/awsui-components-react/polaris/flashbar';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import PageHeader from './Header';
import { useLocation, useParams } from 'react-router-dom';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from 'src/API';
import { API, graphqlOperation } from 'aws-amplify';
import { callPACS, getSiteEvents } from 'src/graphql/queries';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import SiteSummary from './SiteSummary';
import DeviceTests from './DeviceTests';
import { AppLayout, Box, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import {
  NotificationActionType,
  useNotifications,
} from 'src/hooks/notifications';
import * as subscriptions from 'src/graphql/subscriptions';
import Observable from 'zen-observable-ts';
import { ERROR_HEADER_NO_ITEMS_TO_EXPORT } from 'src/constants';
import { useDeviceList } from 'src/features/deviceList';
import { handleContentGlobal } from './helpers';

export default function SitePage() {
  const location = useLocation();
  const { siteName } = useParams();

  const appState = useHookstate(appBaseState);
  const { notifications, dispatch } = useNotifications();
  const [siteDevices, setSiteDevices] = useState<any>(null);
  const [siteLinkedDevices, setSiteLinkedDevices] = useState<any>(null);
  const [siteReaderConfigs, setSiteReaderConfigs] = useState<any>(null);
  const [siteEvents, setSiteEvents] = useState<any[]>([]);
  const [siteDevicesLoaded, setSiteDevicesLoaded] = useState(false);
  const { readers, inputoutputDevices, keypads } = useDeviceList();

  // Export all devices for the site
  const exportSiteDevices = () => {
    const devicesToBeExported = readers
      .concat(keypads)
      .concat(inputoutputDevices);

    if (devicesToBeExported.length == 0) {
      const message: FlashbarProps.MessageDefinition = {
        type: 'error',
        header: ERROR_HEADER_NO_ITEMS_TO_EXPORT,
      };

      dispatch({
        type: NotificationActionType.ADD_NOTIFICATION,
        message: message,
      });
      return;
    }

    let csvContent =
      'deviceName,deviceID,deviceType,UC,parentID,childID,subchildID,testStatus';

    // handleContent
    csvContent = handleContentGlobal(devicesToBeExported, csvContent);

    const csvBlob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('download', `SiteTestingExport-${siteName}-AllDevices.csv`);
    a.click();
  };

  const fetchSiteDevices = async (
    siteRegion: string | undefined = undefined
  ) => {
    try {
      const siteDevices = (await API.graphql(
        graphqlOperation(callPACS, {
          pacsMethod: 'getSiteDevices',
          parameters: JSON.stringify({
            siteName: siteName?.toUpperCase(),
            region: siteRegion,
          }),
        })
      )) as GraphQLResult<APIt.CallPACSQuery>;

      if (siteDevices.errors) {
        return null;
      }

      return siteDevices.data;
    } catch (err) {
      return null;
    }
  };

  const fetchSiteLinkedDevices = async (
    siteRegion: string | undefined = undefined
  ) => {
    try {
      const linkedIODevices = (await API.graphql(
        graphqlOperation(callPACS, {
          pacsMethod: 'getSiteIOLinks',
          parameters: JSON.stringify({
            siteName: siteName?.toUpperCase(),
            region: siteRegion,
          }),
        })
      )) as GraphQLResult<APIt.CallPACSQuery>;

      if (linkedIODevices.errors) {
        return null;
      }

      return linkedIODevices.data;
    } catch (err) {
      return null;
    }
  };

  const fetchSiteReaderConfigs = async (
    siteRegion: string | undefined = undefined
  ) => {
    try {
      const readerConfigData = (await API.graphql(
        graphqlOperation(callPACS, {
          pacsMethod: 'getSiteReaderConfigs',
          parameters: JSON.stringify({
            siteName: siteName?.toUpperCase(),
            region: siteRegion,
          }),
        })
      )) as GraphQLResult<APIt.CallPACSQuery>;

      if (readerConfigData.errors) {
        return null;
      }

      return readerConfigData.data;
    } catch (err) {
      return null;
    }
  };

  const fetchSiteEvents = async (reload = false) => {
    try {
      const siteEvents = (await API.graphql(
        graphqlOperation(getSiteEvents, {
          siteName: siteName?.toUpperCase(),
        })
      )) as GraphQLResult<APIt.GetSiteEventsQuery>;

      if (siteEvents.errors) {
        return null;
      }

      if (!reload) {
        return siteEvents.data;
      } else {
        try {
          const events = JSON.parse(siteEvents.data?.getSiteEvents as string);
          setSiteEvents(events);
        } catch (err) {
          const message: FlashbarProps.MessageDefinition = {
            type: 'error',
            header: 'Failed to update site event',
            content:
              'There was an error refreshing the events for this site. Try refreshing your page to pull the latest events.',
            dismissible: true,
          };

          dispatch({
            type: NotificationActionType.ADD_NOTIFICATION,
            message: message,
          });
        }
      }
    } catch (err) {
      return null;
    }
  };

  const fetchSiteData = async (pacsRegion: string | undefined = undefined) => {
    const siteDevicesFetch = await fetchSiteDevices(pacsRegion);
    const siteLinkedDevicesFetch = await fetchSiteLinkedDevices(pacsRegion);
    const siteReaderConfigsFetch = await fetchSiteReaderConfigs(pacsRegion);

    if (
      !siteDevicesFetch ||
      !siteDevicesFetch.callPACS ||
      !siteDevicesFetch.callPACS.response
    ) {
      const message: FlashbarProps.MessageDefinition = {
        type: 'error',
        header: 'Failed to grab site devices',
        content:
          'There was an error grabbing the devices for this site. Refresh your page to try again.',
        dismissible: true,
      };

      dispatch({
        type: NotificationActionType.ADD_NOTIFICATION,
        message: message,
      });

      throw new Error(
        'There was an error fetching the devices from PACS for this site.'
      );
    } else {
      const siteDevices = JSON.parse(siteDevicesFetch.callPACS.response);
      setSiteDevices(siteDevices);
    }

    const siteLinkedDevicesFetchResp =
      siteLinkedDevicesFetch?.callPACS?.response ?? null;
    const siteReaderConfigsFetchResp =
      siteReaderConfigsFetch?.callPACS?.response ?? null;

    const linkedSiteDevices = siteLinkedDevicesFetchResp
      ? JSON.parse(siteLinkedDevicesFetchResp)
      : null;
    const siteReaderConfigs = siteReaderConfigsFetchResp
      ? JSON.parse(siteReaderConfigsFetchResp)
      : null;

    setSiteLinkedDevices(linkedSiteDevices);
    setSiteReaderConfigs(siteReaderConfigs);

    const [siteEventsFetch] = await Promise.all([fetchSiteEvents()]);

    if (siteEventsFetch == null) {
      const message: FlashbarProps.MessageDefinition = {
        type: 'error',
        header: 'Failed to grab site events',
        content:
          'There was an error grabbing previous events for this site. Refresh your page to try again.',
        dismissible: true,
      };

      dispatch({
        type: NotificationActionType.ADD_NOTIFICATION,
        message: message,
      });

      throw new Error(
        'There was an error fetching events. Check the console for errors.'
      );
    } else {
      const events = siteEventsFetch.getSiteEvents
        ? JSON.parse(siteEventsFetch.getSiteEvents)
        : [];
      setSiteEvents(events);
      setSiteDevicesLoaded(true);
    }
  };

  // Setup our event subscription
  useEffect(() => {
    const sub = (
      API.graphql(
        graphqlOperation(subscriptions.onDeviceEventPublished, {
          siteName: siteName?.toUpperCase(),
        })
      ) as Observable<unknown>
    ).subscribe({
      next: () => {
        fetchSiteEvents(true);
      },
      error: () => {
        const message: FlashbarProps.MessageDefinition = {
          type: 'error',
          header: 'Subscription error',
          content:
            'There was an error subscribing to device events, or the connection was closed. Refresh the page to try again.',
          dismissible: true,
        };

        dispatch({
          type: NotificationActionType.ADD_NOTIFICATION,
          message: message,
        });
      },
    });

    return () => sub.unsubscribe();
  }, [location]);

  // Setup our tests subscription
  useEffect(() => {
    const sub = (
      API.graphql({
        query: subscriptions.onEventTestStarted,
        variables: {
          siteName: siteName?.toUpperCase(),
        },
      }) as Observable<unknown>
    ).subscribe({
      next: async () => {
        fetchSiteEvents(true);
      },
      error: () => {
        const message: FlashbarProps.MessageDefinition = {
          type: 'error',
          header: 'Subscription error',
          content:
            'There was an error subscribing to device tests, or the connection was closed. Refresh the page to try again.',
          dismissible: true,
        };

        dispatch({
          type: NotificationActionType.ADD_NOTIFICATION,
          message: message,
        });
      },
    });

    return () => sub.unsubscribe();
  }, [location]);

  // Setup our cancellation subscription
  useEffect(() => {
    const sub = (
      API.graphql({
        query: subscriptions.onEventTestCancelled,
        variables: {
          siteName: siteName?.toUpperCase(),
        },
      }) as Observable<unknown>
    ).subscribe({
      next: async () => {
        fetchSiteEvents(true);
      },
      error: () => {
        const message: FlashbarProps.MessageDefinition = {
          type: 'error',
          header: 'Subscription error',
          content:
            'There was an error subscribing to device cancellation events, or the connection was closed. Refresh the page to try again.',
          dismissible: true,
        };

        dispatch({
          type: NotificationActionType.ADD_NOTIFICATION,
          message: message,
        });
      },
    });

    return () => sub.unsubscribe();
  }, [location]);

  useEffect(() => {
    setSiteDevicesLoaded(false);
    setSiteDevices(null);
    setSiteEvents([]);

    const pacsSite = appState.loadedSites.value.find(
      site => site.SiteCode === siteName
    );
    const pacsRegion = pacsSite
      ? pacsSite.Site_Region.toUpperCase()
      : undefined;

    fetchSiteData(pacsRegion);
  }, [location]);

  return (
    <AppLayout
      navigationHide={true}
      toolsHide={true}
      contentType="default"
      contentHeader={
        siteDevicesLoaded ? (
          <PageHeader exportSiteDevices={exportSiteDevices} />
        ) : null
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            {
              text: 'Site Testing v2',
              href: '/',
            },
            {
              text: 'Sites',
              href: '#',
            },
            {
              text: `${siteName?.toUpperCase() || ''}`,
              href: '#',
            },
          ]}
        />
      }
      content={
        <SpaceBetween size="l">
          {siteDevicesLoaded ? (
            <SpaceBetween size="l">
              <SiteSummary siteDevices={siteDevices} siteEvents={siteEvents} />
              <DeviceTests
                initSiteDevices={siteDevices}
                siteReaderConfigs={siteReaderConfigs}
                linkedSiteDevices={siteLinkedDevices}
                siteEvents={siteEvents}
              />
            </SpaceBetween>
          ) : (
            <Box textAlign="center">
              <Spinner size="large" />
            </Box>
          )}
        </SpaceBetween>
      }
      stickyNotifications={true}
      notifications={<Flashbar items={notifications.items} />}
    />
  );
}
