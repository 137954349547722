import React from 'react';
import {
  Box,
  ColumnLayout,
  Popover,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { EventsTableItem } from 'src/interfaces';
import { HiLink } from 'react-icons/hi';

function DeviceCellInfo({ item }: { item: EventsTableItem }) {
  return (
    <div>
      <Popover
        fixedWidth
        size="large"
        header={item.device_name}
        content={
          <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="m">
              <div>
                <Box variant="awsui-key-label">Parent Device</Box>
                <div>{item.parent_device_name || '-'}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Child Device</Box>
                <div>{item.child_device_name || '-'}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Subchild Device</Box>
                <div>{item.subchild_device_name || '-'}</div>
              </div>
            </SpaceBetween>
            <SpaceBetween size="m">
              <div>
                <Box variant="awsui-key-label">Parent Device ID</Box>
                <div>{item.parent_device_id || '-'}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Child Device ID</Box>
                <div>{item.child_device_id || '-'}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Subchild Device ID</Box>
                <div>{item.subchild_device_id || '-'}</div>
              </div>
              {item.linked_device_id ? (
                <div>
                  <Box variant="awsui-key-label">Linked Device ID</Box>
                  <div>{item.linked_device_id || '-'}</div>
                </div>
              ) : null}
            </SpaceBetween>
          </ColumnLayout>
        }>
        {item.device_name}
        {item.linked_device_id ? <HiLink className={'inline'} /> : null}
      </Popover>
    </div>
  );
}

export default DeviceCellInfo;
