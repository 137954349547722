import React, { useEffect, useReducer, useState } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { Auth } from 'aws-amplify';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import {
  LoadingReducer,
  SERVER_REQUEST_END,
  SERVER_REQUEST_START,
} from 'src/hooks/loading';
import { KatSpinner } from '@amzn/katal-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopNav from './components/TopNav';
import SiteNavigation from './components/SiteSelection';
import SitePage from './components/SitePage';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import IsItDown from './components/IsItDown';
import { CognitoUser } from '@aws-amplify/auth';
import { DeviceListProvider } from './features/deviceList';

function App() {
  const appState = useHookstate(appBaseState);
  const [{ loading }, dispatch] = useReducer(LoadingReducer, { loading: true });
  const [siteSelectVisible, setSiteSelectVisible] = useState(false);

  // set app loading state and authenticate
  useEffect(() => {
    dispatch({ type: SERVER_REQUEST_START });

    const setUserData = (cognitoUserData: CognitoUser) => {
      const session = cognitoUserData.getSignInUserSession();
      if (!session) {
        return;
      }

      const idToken = session.getIdToken();
      const userId = idToken.payload['identities'][0].userId;
      let userGroups = [];

      try {
        userGroups = idToken.payload['custom:posix'].split(',');
      } catch {
        userGroups = [];
      }

      appState.user.username.set(userId);
      appState.user.userGroups.set(userGroups);
    };

    Auth.currentAuthenticatedUser()
      .then((cognitoUserData: CognitoUser) => {
        setUserData(cognitoUserData);
        dispatch({ type: SERVER_REQUEST_END });
      })
      .catch(() => {
        Auth.federatedSignIn({
          customProvider: 'AmazonFederate',
        }).catch(() => {
          // ignore
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <KatSpinner size="large" />
      ) : (
        <div>
          {appState.user.username.value ? (
            <div>
              <IsItDown />
              <Routes>
                <Route
                  index
                  element={
                    <SiteNavigation
                      isVisible={siteSelectVisible}
                      setVisible={setSiteSelectVisible}
                    />
                  }
                />
                <Route
                  path="/site/:siteName"
                  element={
                    <div>
                      <TopNav
                        setSiteSelectVisible={setSiteSelectVisible}
                        appState={appState}
                      />
                      {appState.loadedSites.value.length > 0 ? (
                        <DeviceListProvider>
                          <SitePage />
                        </DeviceListProvider>
                      ) : (
                        <Box textAlign="center">
                          <StatusIndicator type="loading">
                            Waiting for site data..
                          </StatusIndicator>
                        </Box>
                      )}
                      {/* <SitePage /> */}
                      <SiteNavigation
                        isVisible={siteSelectVisible}
                        setVisible={setSiteSelectVisible}
                      />
                    </div>
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          ) : (
            <div>
              <Box
                margin={{ top: 's', left: 's' }}
                color="inherit"
                display="block"
                fontSize="heading-l">
                You do not have access to this page. Please contact
                <Link
                  external
                  href="https://w.amazon.com/index.php/SIDE#HContactus">
                  {' '}
                  SIDE Support
                </Link>{' '}
                if you have any questions.
              </Box>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
